class puntoTukson extends Component {

    static  name() {
        return "PuntoTuksonPage";
    }

    static componentName() {
        return "PuntoTuksonPage";
    }


    getTemplate() {
        return `<div class="static-page new-point">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="static-banner">
                        <img class="d-lg-none img-fluid" src="https://tuksonprueba.oppen.io/assets/BANNER_PUNTO_TUKSON_MOBILE">
                        <img class="d-none d-lg-block img-fluid" src="https://tuksonprueba.oppen.io/assets/BANNER_PUNTO_TUKSON">
                    </div>
                    <div class="section-content container">
                        <form id="newpoint" class="row justify-content-center">
                            <h4 class="text-center w-100 new-point-title">Completá el formulario con tus datos para convertirte en Punto <b>Tukson.</b></h4>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtName" name="Name" type="text" class="custom-input" placeholder="Nombre y apellido de contacto">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtBrand" name="Brand" type="text" class="custom-input" placeholder="Nombre de marca / Empresa">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtCUIT" name="Cuit" type="text" class="custom-input" placeholder="CUIT o DNI">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtAddress" name="Address" type="text" class="custom-input" placeholder="Dirección">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtPhoneNumber" name="PhoneNumber" type="text" class="custom-input" placeholder="Teléfono">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtProvince" name="Province" type="text" class="custom-input" placeholder="Provincia">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtEmail" name="Email" type="text" class="custom-input" placeholder="E-mail">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtCity" name="City" type="text" class="custom-input" placeholder="Ciudad">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtIG" name="IG" type="text" class="custom-input" placeholder="Redes / Instagram">
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 form-group">
                                <input id="txtFB" name="FB" type="text" class="custom-input" placeholder="Redes / Facebook">
                            </div>
                            <div class="text-center mt-2">
                                <button type="button" class="send-button">
                                    <p>Enviar</p><i class="far fa-arrow-alt-circle-right fa-2x"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

puntoTukson.registerComponent();
